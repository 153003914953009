import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4CAF50", // Green color (adjust based on branding)
    },
    secondary: {
      main: "#FFC107", // Amber color (adjust based on branding)
    },
    background: {
      default: "#FFFFFF", // Light background for the app
      paper: "#FFFFFF", // White background for cards and components
    },
    text: {
      primary: "#212121", // Dark text color
      secondary: "#757575", // Secondary text color
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif", // Change as per brand fonts
    h1: {
      fontWeight: 700,
      fontSize: "2.5rem", // Large heading
      color: "#4CAF50", // Primary color for headers
    },
    h2: {
      fontWeight: 600,
      color: "#4CAF50",
    },
    h3: {
      fontWeight: 600,
      color: "#4CAF50",
    },
    h4: {
      fontWeight: 600,
      color: "#4CAF50",
    },
    button: {
      textTransform: "none", // Avoiding uppercase text on buttons
      fontWeight: "bold",
      color: "#FFFFFF", // White text for buttons
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "20px", // Round buttons for a sleek look
          padding: "10px 20px",
          backgroundColor: "#4CAF50", // Primary button color
          "&:hover": {
            backgroundColor: "#388E3C", // Darker shade on hover
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          backgroundColor: "#f0f0f0", // Light background for input fields
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Slightly rounded corners for paper
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow
        },
      },
    },
  },
});

export default theme;
