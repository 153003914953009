import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Container } from "@mui/material";
import theme from "./themes/theme"; // Custom theme for Material UI
import Login from "./components/auth/login";
import Dashboard from "./components/dashboard/dashboard";
import PrivateRoute from "./components/auth/privateRoute"; // Custom private route for authenticated users
import BusinessCardDisplay from "./components/dashboard/businessCardDisplay"; // Import the business card display component
import NotFound from "./components/notfound"; // Import a NotFound component

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        {/* Container that adjusts size based on the current path */}
        <Routes>
          {/* Public route for displaying the business card (QR code landing page) */}
          <Route path="/business-card/:id" element={<BusinessCardDisplay />} />

          {/* Login page with smaller container width */}
          <Route
            path="/"
            element={
              <Container maxWidth="xs">
                <Login />
              </Container>
            }
          />

          {/* Protected route for the dashboard with larger container width */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Container maxWidth="md">
                  <Dashboard />
                </Container>
              </PrivateRoute>
            }
          />

          {/* 404 Not Found route */}
          <Route
            path="*"
            element={
              <Container maxWidth="sm">
                <NotFound />
              </Container>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
