import React, { useState } from "react";
import { TextField, Button, Grid, Typography, Paper, Box } from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../configs/firebaseConfig";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png"; // Assume you have a logo image

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (err) {
      setError("Invalid login credentials. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        // backgroundColor: "#f0f4f8", // Light background color
        p: 3,
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          width: "100%",
          // maxWidth: 400,
          textAlign: "center",
          // mb: 4,
          mt: 5,
        }}
      >
        <img
          src={logo}
          alt="Company Logo"
          style={{ maxWidth: "450px", height: "auto" }} // Centered logo with fixed width
        />
      </Box>

      {/* Login Form */}
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: 400,
          padding: "32px",
          borderRadius: "12px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow
          backgroundColor: "#ffffff", // Clean white background
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            fontWeight: "bold",
            color: "#2A3F54", // Dark professional color
            mb: 2,
          }}
        >
          Admin Login
        </Typography>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          sx={{ color: "#6c757d", mb: 4 }} // Muted text color
        >
          Please enter your credentials to access the dashboard.
        </Typography>

        <form onSubmit={handleLogin}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#ced4da",
                    },
                    "&:hover fieldset": {
                      borderColor: "#2A3F54",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#ced4da",
                    },
                    "&:hover fieldset": {
                      borderColor: "#2A3F54",
                    },
                  },
                }}
              />
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Typography color="error" align="center">
                  {error}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                sx={{
                  backgroundColor: "#2A3F54",
                  ":hover": {
                    backgroundColor: "#1f2e3e",
                  },
                  borderRadius: 3,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow effect
                  fontWeight: "bold",
                  padding: "12px",
                }}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      {/* Footer Section */}
      <Box
        sx={{
          mt: 4,
          textAlign: "center",
          color: "#6c757d",
          fontSize: "0.85rem",
        }}
      >
        <Typography variant="body2">Copyright © 2024</Typography>
        <Typography variant="body2">All rights reserved.</Typography>
      </Box>
    </Box>
  );
};

export default Login;
