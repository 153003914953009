import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../configs/firebaseConfig";
import {
  Box,
  Typography,
  Button,
  Avatar,
  CircularProgress,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import ContactsIcon from "@mui/icons-material/Contacts";
import WebIcon from "@mui/icons-material/Web";
import logo from "../../assets/logo.png";

const BusinessCardDisplay = () => {
  const { id } = useParams(); // Get the business card ID from the URL
  const [businessCard, setBusinessCard] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBusinessCard = async () => {
      try {
        const docRef = doc(db, "businessCards", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBusinessCard(docSnap.data());
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching business card:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessCard();
  }, [id]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!businessCard) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6">Business card not found.</Typography>
      </Box>
    );
  }

  // Function to generate vCard content
  const generateVCard = () => {
    return `BEGIN:VCARD
VERSION:3.0
FN:${businessCard.name}
ORG:${businessCard.company}
TITLE:${businessCard.position}
TEL;TYPE=CELL:${businessCard.phone}
EMAIL:${businessCard.email}
END:VCARD`;
  };

  // Function to download the vCard
  const handleAddToContacts = () => {
    const vCardContent = generateVCard();
    const blob = new Blob([vCardContent], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = `${businessCard.name}.vcf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: "16px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 400,
          // backgroundColor: "#fff", // Removed boxShadow and borders
          paddingBottom: "16px",
        }}
      >
        {/* Logo Section */}
        <Box sx={{ textAlign: "center", mb: 10 }}>
          <img
            src={logo}
            alt="Company Logo"
            style={{ maxWidth: "100%", height: "auto", borderRadius: "16px" }}
          />
        </Box>

        {/* Name and Profile Background */}
        <Box
          sx={{
            backgroundColor: "#074e56", // Dark blue for name background
            padding: "32px",
            textAlign: "center",
            position: "relative",
            mt: 12, // Increased margin top to give space for the avatar
            borderRadius: "16px",
          }}
        >
          {/* Profile Image (Halfway Overlapping) */}
          <Avatar
            alt={businessCard.name}
            src={businessCard.profilePicURL}
            sx={{
              width: 200,
              height: 200,
              mx: "auto",
              border: "5px solid #fff",
              position: "absolute",
              top: -100, // Ensure avatar is positioned but fully visible
              left: "50%",
              transform: "translateX(-50%)",
              borderRadius: "50%", // Keep the avatar round
            }}
          />

          {/* Name and Title */}
          <Typography
            align="center"
            variant="h5"
            component="div"
            sx={{
              fontWeight: 600,
              color: "#fff",
              mb: 1,
              mt: 10, // Ensure enough space for the overlapping avatar
            }}
          >
            {businessCard.name}
          </Typography>
          <Typography
            align="center"
            variant="subtitle1"
            color="rgba(255, 255, 255, 0.7)"
            sx={{ mb: 2 }}
          >
            {businessCard.position || "Associate"}
          </Typography>

          {/* Bio/Description */}
          <Typography
            variant="body2"
            color="rgba(255, 255, 255, 0.85)"
            align="justify"
            sx={{ mb: 2 }}
          >
            {businessCard.bio ||
              `This person is an expert in their field and has contributed extensively to the company's growth.`}
          </Typography>
        </Box>

        {/* Action Buttons */}
        <Box
          sx={{
            // padding: "16px",
            width: "100%", // Ensure buttons are full width like the background
          }}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<PhoneIcon />} // Call Icon
            sx={{
              mb: 2,
              mt: 2,
              backgroundColor: "#94c918",
              ":hover": { backgroundColor: "#94c918" },
              borderRadius: "16px", // Matching the background radius
            }}
            href={`tel:${businessCard.phone}`}
          >
            Call {businessCard.name}
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<EmailIcon />} // Email Icon
            sx={{
              mb: 2,
              backgroundColor: "#94c918",
              ":hover": { backgroundColor: "#94c918" },
              borderRadius: "16px", // Matching the background radius
            }}
            href={`mailto:${businessCard.email}`}
          >
            Email {businessCard.name}
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<WebIcon />} // Email Icon
            sx={{
              mb: 2,
              backgroundColor: "#94c918",
              ":hover": { backgroundColor: "#94c918" },
              borderRadius: "16px", // Matching the background radius
            }}
            href={"https://greenloop.co.bw/"}
            target="_blank"
          >
            Visit website
          </Button>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<ContactsIcon />} // Contacts Icon
            sx={{
              backgroundColor: "#94c918",
              ":hover": { backgroundColor: "#94c918" },
              // color: "#94c918",
              // ":hover": { borderColor: "#94c918", color: "#94c918" },
              borderRadius: "16px", // Matching the background radius
            }}
            onClick={handleAddToContacts}
          >
            Add to Contacts
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BusinessCardDisplay;
