import React, { useState, useRef } from "react";
import {
  Typography,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "../../configs/firebaseConfig";
import { useNavigate } from "react-router-dom";
import BusinessCardList from "./businessCardList";
import BusinessCardForm from "./businessCardForm";

const Dashboard = () => {
  const navigate = useNavigate();
  const [openForm, setOpenForm] = useState(false);
  const businessCardListRef = useRef(); // Reference for the business card list component

  const handleLogout = async () => {
    await signOut(auth);
    navigate("/"); // Redirect to login page after logout
  };

  const handleOpenForm = () => {
    setOpenForm(true); // Show the modal form
  };

  const handleCloseForm = (newCardAdded = false) => {
    setOpenForm(false); // Close the modal form
    // If a new card was added, call the fetchBusinessCards method to reload the list
    if (newCardAdded && businessCardListRef.current) {
      businessCardListRef.current.fetchBusinessCards(); // Trigger fetching new cards
    }
  };

  return (
    <Box sx={{ mt: 4, px: 3 }}>
      {/* Dashboard Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          mb: 3,
          backgroundColor: "#f5f5f5",
          py: 2,
          px: 3,
          borderRadius: "8px",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Admin Dashboard
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenForm}
            sx={{ mr: 2 }}
          >
            Add Business Card
          </Button>
          <Button variant="contained" color="secondary" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Box>

      {/* Business Card List */}
      <BusinessCardList ref={businessCardListRef} />

      {/* Add Business Card Form Modal */}
      <Dialog
        open={openForm}
        onClose={() => handleCloseForm(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add New Business Card</DialogTitle>
        <DialogContent>
          <BusinessCardForm onAddCard={() => handleCloseForm(true)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseForm(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;
